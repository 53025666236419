<template>
  <div 
  >
<!-- 
    class="q-pa-md" 
    style="position: relative;min-height: calc(100% - 160px); height: calc(100% - 160px); max-height: calc(100% - 160px); overflow: auto;"
 -->
    <div class="row">
      
        <item-documento
          v-for="catalogo in catalogoOrdenado"
          :key="catalogo.id"
          :catalogo="catalogo" 
          :documento="documentos[catalogo.id + '']"
          @documento-updated="loadDocumnentos"
        >
        </item-documento>

    </div>
    <q-inner-loading :showing="loading">
      <q-spinner-orbit v-show="loading" size="100px" color="indigo" />
    </q-inner-loading>
  </div>
</template>

<script>
import itemDocumento from 'components/page-documents/item-documento.vue'

export default {
  name: 'lista-documentos',
  components: { itemDocumento },
  data () {
    return {
      loading: false,
      catalogos: [],
      documentos: {}
    }
  },
  computed: {
    catalogoOrdenado () {
      const order = this.$q.screen.lt.md ? 'orden_movil' : 'orden_web'
      const catalogos = [...this.catalogos]
      return catalogos.sort((item1, item2) => (item1[order] - item2[order]))
    }
  },
  methods: {
    getDocumentos () {
      const params = { 'per-page': 'all' }
      return this.$cannaApiService.get('/profile/documento', { params })
        .then(({ data }) => {
          data.forEach(documento => {
            this.documentos[documento.catalogo_id] = documento
          })
        })
    },
    getCategorias () {
      const sort = this.$q.screen.lt.md ? 'orden_movil' : 'orden_web'
      const params = { 'per-page': 'all', sort }
      return this.$cannaApiService.get('categoria/64/catalogo', { params })
        .then(({ data }) => {
          this.catalogos = data.filter(({ id }) => (id !== 772))
          this.$emit('length', this.catalogos.length)
        })
    },
    async loadDocumnentos () {
      this.loading = true
      await this.getDocumentos()
      this.loading = false
    },
    async init () {
      try {
        this.loading = true
        await this.getCategorias()
        await this.getDocumentos()
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    catch (e) {
      this.$cannaDebug(e)
    }
  },
  mounted () {
    this.init()
  }
}
</script>
