<template>
  <div style="height: 100%; overflow: hidden;">
    <div class="row">
      <div class="col q-pa-md">
        <div class="text-weight-medium" style="font-size: 30px;">Documentos</div>
        <div class="text-grey-7">
          Aquí podrás subir los archivos escaneados de tu documentación básica original
        </div>
      </div>
    </div>
    <q-separator color="grey-4" />
    <div class="row">
      <div class="q-pa-md">
        <div class="text-weight-medium text-grey-9" style="font-size: 18px;">
          {{ lengthDocumentos }} Documentos
        </div>
      </div>
    </div>
    <q-separator color="grey-3" />

    <template v-if="showDocsAlert">
      <div id="alertDocsInstructions"
        class="alertaInfoDocs"
        :style="alertStyle"
      >
        <div class="text-center" style="flex:1;"
          :style="(is_Mobile || $q.screen.lt.sm)  ? 'padding: 0px 40px; font-size: 15px;' : ''"
        >
          ¡Hey! Subir tus documentos no es obligatorio, sin embargo, al hacerlo 
          <template v-if="(is_Mobile || $q.screen.lt.sm) ? false: true"> <br> </template> 
          tendrás tu expediente completo y puede ahorrarte tiempo en trámites futuros.
        </div>
        <div style="margin-left: 16px;">
          <cxIcon
            iconType="ui_close"
            size="24px"
            class=""
            :cls="'icon_close_svgAlt'"
            :state="0"
            :hasMouseOver="true"
            :hasAction="true"
            :allowDisabled="true"
            :allowClick="true"
            @iconClick=" showDocsAlert = !showDocsAlert "
          />
        </div>
      </div>
    </template>

    <div class="q-pa-md" 
      style="position: relative;"
      :style="docsContainerStyle"
    >

      <lista-documentos @length="lengthDocumentos = $event">
      </lista-documentos>

    </div>


  </div>
</template>

<script>
import listaDocumentos from 'components/page-documents/lista-documentos.vue'
import myplatform_Mixin from './../../../mixins/platformMix.js'
import cxIcon from 'components/appIcons.vue'

export default {
  name: 'index-documents',
  components: { listaDocumentos , cxIcon },
  mixins: [
    myplatform_Mixin
  ],
  data () {
    return {
      showDocsAlert: true,
      lengthDocumentos: 0
    }
  }, 
  computed:{
    alertStyle () { 
      var alt_height = (this.is_Mobile || this.$q.screen.lt.sm) ? 89 : 49
      return 'min-height: ' + alt_height+ 'px; height: ' + alt_height+ 'px; max-height: ' + alt_height+ 'px;'
    },
    docsContainerStyle (){
      var alt_height = (this.is_Mobile || this.$q.screen.lt.sm) ? 89 : 49
      var useH = 160 + alt_height
      // 184px
      // 272px
      var mystyle = (this.is_Mobile || this.$q.screen.lt.sm) ?  
        (this.showDocsAlert===true) ?
          'min-height: calc(100% - 272px); height: calc(100% - 272px); max-height: calc(100% - 272px); overflow: auto;' :
          'min-height: calc(100% - 184px); height: calc(100% - 184px); max-height: calc(100% - 184px); overflow: auto;'
        : 
         (this.showDocsAlert===true) ?
          'min-height: calc(100% - 209px); height: calc(100% - 209px); max-height: calc(100% - 209px); overflow: auto;': 
          'min-height: calc(100% - 160px); height: calc(100% - 160px); max-height: calc(100% - 160px); overflow: auto;'   
      
      return mystyle
    }
  }
}
</script>
