<template>
  <q-expansion-item
    :key="catalogo.id"
    :label="catalogo.nombre"
    header-class="q-py-md q-px-lg"
    class="q-my-sm"
    @before-show="show = true"
    style="box-shadow: 0px 4px 8px #dcdbe1;border-radius: 8px;overflow: hidden;width: 100%;"
  >
    <template v-slot:header>
      <div class="row" style="width: 100%;">
        <div class="q-item__label">
          <div class="text-weight-medium q-py-sm" style="font-size: 16px">
            {{ catalogo.nombre }}
          </div>
          <div class="q-mb-sm">
            <span class="text-weight-medium" :style="styleStatus">
              {{ labelStatus }}
            </span>
            <span class="text-grey-7">
              {{ dateLabel }}
            </span>
          </div>
        </div>
      </div>
    </template>
    <div class="q-pa-md">
      <template v-if="documento.documento">
        <div class="col">
          <document-view
            :key="documento._links['file-stream'].href"
            :src="documento._links['file-stream'].href"
            :file-name="documento.documento"
            @ready="src = $event"
            @preview-able="previewAble = $event"
            class="rounded-borders q-pa-sm text-center"
            style="background-color: #FAF9FA;"
          />
          <div class="q-my-md q-pa-sm" style="background-color: #FAF9FA" v-if="src">
            <div class="row">
              <cxIcon
                iconType="cannav2_docs_attachment"
                size="40px"
                class="q-pr-sm"
                :cls="''"
                :overCls="''"
                :state="0"
                :hasMouseOver="false"
                :hasAction="false"
                :allowDisabled="false"
                :allowClick="false"
                style="margin-right: 10px;"
              />
              <div class="col">
                <div class="text-caption-basic" style="font-size: 15px">Archivo adjunto:</div>
                <div>
                  <router-link
                    :to="{ path: '/pdf-viewer', query: { src, fileName: documento.documento } }"
                    custom
                    v-slot="{ href }"
                  >
                    <a
                      style="text-decoration: none;font-size: 15px"
                      class="text-weight-medium text-app_mainBtn"
                      :download="!$q.screen.lt.sm && previewAble ? null : documento.documento"
                      :href="!$q.screen.lt.sm && previewAble ? href : src"
                      :target="!$q.screen.lt.sm && previewAble ? '_blank' : null"
                    >
                      {{ documento.nombre_original }}
                    </a>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="text-app_mainBtn" v-if="(documento.estatus === 12) ? false : true ">
            <span class="cursor-pointer" @click="$refs.inputFile.pickFiles()">
              {{ loading ? 'Subiendo nuevo archivo...' : 'Subir un nuevo archivo' }}
            </span>
          </div>

          <div class="row justify-start items-center q-my-md" v-if="errorMessage">
            <div class="q-mr-sm"><img src="images/login_warning.png"/></div>
            <div>{{ errorMessage }}</div>
          </div>
        </div>
      </template>
      
      <template v-if="(documento.estatus === 12) ? false : true ">
        <div class="row justify-center q-py-md" style="background-color: #FAF9FA;" v-show="!documento.documento">
          <div class="col col-auto">
            <input-file
              style="margin: 0 auto;"
              accept=".jpg,.jpeg,.png,.pdf"
              v-model="inputfile"
              ref="inputFile"
              sm-label="Elige un archivo de tu dispositivo."
              md-label="Arrastra y suelta aquí tu documento o elige un archivo de tu dispositivo."
              accept-label="JPG, PNG, o PDF"
              :max-file-size="5e+6"
              @input="updateFile"
              @rejected="messageValidation"
            />
            <div v-show="loading">
              <q-linear-progress size="42px" :value="progress" color="app_mainBtn" class="q-mt-sm">
                <div class="absolute-full flex flex-center">
                  <q-badge color="white" text-color="accent" :label="(progress * 100).toFixed(2) + '%'" />
                </div>
              </q-linear-progress>
            </div>
            <div class="row justify-center items-center q-my-md" v-if="errorMessage">
              <div class="q-mr-sm"><img src="images/login_warning.png"/></div>
              <div>{{ errorMessage }}</div>
            </div>
          </div>
        </div>
      </template>

    </div>
  </q-expansion-item>
</template>

<script>
import inputFile from 'components/input-file'
import documentView from 'components/page-documents/document-view'
import cxIcon from 'components/appIcons.vue'

export default {
  name: 'item-documento',
  components: { inputFile, documentView, cxIcon },
  props: {
    catalogo: {
      type: Object,
      default () {
        return {}
      }
    },
    documento: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      show: false,
      statusLabels: {
        10: 'Pendiente de verificar',
        11: 'Rechazado',
        12: 'Verificado',
        14: 'Documento solicitado'
      },
      statusStyles: {
        10: 'color: #F2800D',
        11: 'color: #F53D68',
        12: 'color: #3CDDA7',
        14: 'color: #AFADB8'
      },
      inputfile: null,
      loading: false,
      progress: 0,
      src: '',
      previewAble: false,
      errorMessage: ''
    }
  },
  computed: {
    labelStatus () {
      return this.statusLabels[this.documento.estatus] || 'Por favor, sube este documento'
    },
    styleStatus () {
      return this.statusStyles[this.documento.estatus] || 'color: #AFADB8'
    },
    dateLabel () {
      if (!this.documento.updated_at) return ''
      return this.$moment(this.documento.updated_at, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
    }
  },
  methods: {
    messageValidation (errors) {
      errors.forEach(({ failedPropValidation }) => {      
        const mensajes = {
          'max-file-size':  'Al parecer tu documento pesa más de lo permitido. Peso máximo 5MB',
          accept: 'Al parecer tu documento no tiene el formato permitido'
        }
        const message = mensajes[failedPropValidation]
        if (message) this.errorMessage = message
      })
    },
    addFile () {
      const { id: catalogo_id } = this.catalogo
      const params = this.$objToForm({ catalogo_id, file: this.inputfile})
      this.progress = 0
      return this.$cannaApiService.post('/profile/documento', params, {
        onUploadProgress: progressEvent => {
          this.progress = Math.round((progressEvent.loaded) / progressEvent.total)
        }
      })
    },
    deleteFile () {
      const { id: documento_id } = this.documento
      return this.$cannaApiService.delete('profile/documento/' + documento_id)
    },
    async updateFile () {
      if (!this.inputfile) return
      this.loading = true
      this.errorMessage = ''
      
      try {
        const newFile = await this.addFile()
        if (this.documento.documento) await this.deleteFile().catch(() => (null))
        this.$emit('documento-updated', newFile)
      } catch (e) {
        console.log(e)
      }

      this.inputfile = null
      this.loading = false
    }
  }
}
</script>
